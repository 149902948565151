import React, {FC, useEffect, useState} from 'react'
import {Flex, Spacer, StatDownArrow, StatUpArrow, Text, Tooltip} from "@chakra-ui/react";
import {ProveDashboardBreakdownConfig} from "@components/organisms/prove/ProveDashboardBody";
import {useTranslation} from "react-i18next";
import {Column, DataTable, Row} from "@components/molecules";
import {useSelector} from "@redux";
import {proveDashboardOverviewData} from "@redux/slices/prove/dashboard/selectors";
import {Chart} from "react-chartjs-2";
import {ProveFlattenedReportBreakdownEntry} from "@api-clients/prove/schema/ProveDashboard";

interface ProveDashboardTableProps {
    breakdownConfig: ProveDashboardBreakdownConfig;

    valueFormatter(type: string, value: string): string;

    breakdownData: Array<ProveFlattenedReportBreakdownEntry>
}

interface AttentionDecayEntry {
    label: string,
    value: number
}

interface TableDataEntry {
    breakdown: Array<string>,
    impressions: number,
    avgActive: number,
    avgPassive: number,
    activeAttentionDecay: Array<AttentionDecayEntry>
}


export const ProveDashboardTable: FC<ProveDashboardTableProps> = ({
                                                                      breakdownConfig,
                                                                      valueFormatter,
                                                                      breakdownData
                                                                  }) => {

    const dashboardOverviewDataSelector = useSelector(proveDashboardOverviewData);

    const [tableColumns, setTableColumns] = useState(undefined as Array<Column<TableDataEntry>> | undefined);
    const [tableData, setTableData] = useState(undefined as Array<Row<TableDataEntry>> | undefined);

    const {t} = useTranslation('prove');
    const content = t('prove.dashboard', {
        returnObjects: true,
    });

    const calculatePercentage = (numerator: number, denominator: number) => {
        return (numerator / denominator) * 100;
    }

    const formatPercentage = (value: number) => {
        return `${value.toFixed(1)}% of impressions`;
    }

    const formatCountMetric = (metric: number) => {
        if (metric < 1000) {
            return metric.toLocaleString();
        }

        if (metric < 1000000) {
            return `${Math.round(metric / 1000)}K`;
        }

        return `${(metric / 1000000).toFixed(3)}M`;
    }

    const buildInlineChart = (entries: Array<AttentionDecayEntry>) => {
        return (<Chart height={50} width={200} type='line'
                       data={{
                           labels: entries.map((entry) => {
                               return entry.label
                           }),
                           datasets: [
                               {
                                   data: entries.map((entry) => {
                                       return entry.value
                                   }),
                                   fill: false,
                                   spanGaps: true,
                                   tension: 0.2,
                                   backgroundColor: 'green.500'
                               }
                           ]
                       }}
                       options={{
                           maintainAspectRatio: false,
                           responsive: false,
                           plugins: {
                               legend: {
                                   display: false
                               },
                               tooltip: {
                                   callbacks: {
                                       label: () => {
                                           return '';
                                       },
                                       afterBody: (tooltipItems) => {
                                           const data = tooltipItems.at(0)!.raw as number;

                                           return [
                                               `${formatPercentage(data)}`
                                           ];
                                       },
                                   },
                               },
                           },
                           scales: {
                               x: {
                                   display: false,
                               },
                               y: {
                                   display: false,
                                   max: 100
                               }
                           },
                       }}/>);
    }

    const updateTableColumns = () => {
        const columns = breakdownConfig.fields.map((field, index) => {
            return {
                title: field.label,
                dataIndex: field.id,
                key: field.id,
                isNumeric: false,
                sorter: (a, b) => a.breakdown[index].localeCompare(b.breakdown[index]),
                render: (row: Row<TableDataEntry>) => {
                    const formattedValue = valueFormatter(field.id, row.breakdown[index]);

                    return (
                        <Tooltip label={formattedValue}>
                            <Text noOfLines={1} maxWidth={250}>
                                {formattedValue}
                            </Text>
                        </Tooltip>
                    );
                }
            } as Column<TableDataEntry>;
        });

        columns.push(
            {
                title: content.breakdown.labels.impressions,
                dataIndex: 'impressions',
                key: 'impressions',
                isNumeric: true,
                sorter: (a, b) => b.impressions - a.impressions,
                render: (row) => <>{formatCountMetric(row.impressions)}</>,
            },
            {
                title: content.breakdown.labels.avgActive,
                dataIndex: 'avgActive',
                key: 'avgActive',
                isNumeric: true,
                sorter: (a, b) => b.avgActive - a.avgActive,
                render: (row) => <Flex><Spacer/><Text
                    mr={2}>{row.avgActive.toLocaleString()}s</Text>{(row.avgActive < dashboardOverviewDataSelector!.avgActiveAttention) ?
                    <StatDownArrow/> : <StatUpArrow/>}</Flex>,
            },
            {
                title: content.breakdown.labels.avgPassive,
                dataIndex: 'avgPassive',
                key: 'avgPassive',
                isNumeric: true,
                sorter: (a, b) => b.avgPassive - a.avgPassive,
                render: (row) => <Flex><Spacer/><Text
                    mr={2}>{row.avgPassive.toLocaleString()}s</Text>{(row.avgPassive < dashboardOverviewDataSelector!.avgPassiveAttention) ?
                    <StatDownArrow/> : <StatUpArrow/>}</Flex>,
            },
            {
                title: content.breakdown.labels.activeAttentionDecay,
                dataIndex: 'activeAttentionDecay',
                key: 'activeAttentionDecay',
                render: (row) => buildInlineChart(row.activeAttentionDecay)
            }
        );

        setTableColumns(columns);
    }

    const updateTableRows = () => {
        const rows = breakdownData!.map((breakdownDataItem, index) => {
            return {
                key: breakdownDataItem.value.join("-"),
                breakdown: breakdownDataItem.value,
                impressions: breakdownDataItem.metrics.impressions,
                avgActive: breakdownDataItem.metrics.avgActiveAttention,
                avgPassive: breakdownDataItem.metrics.avgPassiveAttention,
                activeAttentionDecay: [
                    {
                        label: `1s ${content.breakdown.labels.activeAttention}`,
                        value: calculatePercentage(breakdownDataItem.metrics!.impressionsActive1s!, breakdownDataItem.metrics.impressions)
                    },
                    {
                        label: `2s ${content.breakdown.labels.activeAttention}`,
                        value: calculatePercentage(breakdownDataItem.metrics!.impressionsActive2s!, breakdownDataItem.metrics.impressions)
                    },
                    {
                        label: `3s ${content.breakdown.labels.activeAttention}`,
                        value: calculatePercentage(breakdownDataItem.metrics!.impressionsActive3s!, breakdownDataItem.metrics.impressions)
                    },
                    {
                        label: `4s ${content.breakdown.labels.activeAttention}`,
                        value: calculatePercentage(breakdownDataItem.metrics!.impressionsActive4s!, breakdownDataItem.metrics.impressions)
                    },
                    {
                        label: `5s ${content.breakdown.labels.activeAttention}`,
                        value: calculatePercentage(breakdownDataItem.metrics!.impressionsActive5s!, breakdownDataItem.metrics.impressions)
                    }
                ]
            } as Row<TableDataEntry>;
        });

        setTableData(rows);
    }

    useEffect(() => {
        if (!dashboardOverviewDataSelector || !breakdownData) {
            return;
        }

        updateTableRows();
    }, [dashboardOverviewDataSelector, breakdownData])

    useEffect(() => {
        updateTableColumns();
    }, [breakdownConfig])

    return (
        <>
            {tableColumns && tableData && (
                <>
                    <DataTable
                        isPaginated
                        defaultPageSize={10}
                        dataSource={tableData}
                        columns={tableColumns}
                        onRow={(row) => ({
                            _hover: {
                                bgColor: 'gray.50'
                            },
                        })}
                    />
                </>
            )}
        </>
    )
}
