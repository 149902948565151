import {createAppAsyncThunk} from "@/redux/createAppAsyncThunk";
import {ProveCampaign} from "@api-clients/prove/schema/ProveCampaign";
import {ProveLineItem} from "@api-clients/prove/schema/ProveLineItem";
import {RetrieveProveBreakdownReportDataApi} from "@api-clients/prove/api/RetrieveProveBreakdownReportDataApi";
import {RetrieveProveOverviewReportDataApi} from "@api-clients/prove/api/RetrieveProveOverviewReportDataApi";
import {Configuration} from "@api-clients/runtime";
import {proveOverviewReportDemoData} from "@redux/slices/prove/dashboard/demo/proveOverviewReport";
import {proveBreakdownHierarchyReportDemoData} from "@redux/slices/prove/dashboard/demo/proveBreakdownHierarchyReport";
import {proveBreakdownDeviceReportDemoData} from "@redux/slices/prove/dashboard/demo/proveBreakdownDeviceReport";
import {proveBreakdownPublisherReportDemoData} from "@redux/slices/prove/dashboard/demo/proveBreakdownPublisherReport";
import {proveBreakdownAdFormatReportDemoData} from "@redux/slices/prove/dashboard/demo/proveBreakdownAdFormatReport";
import {proveBreakdownLineItemReportDemoData} from "@redux/slices/prove/dashboard/demo/proveBreakdownLineItemReport";

export const retrieveProveOverviewReportDataAsync = createAppAsyncThunk(
    'prove/dashboard/overviewReportData',
    async (
        payload: {
            configuration: Configuration,
            campaign: ProveCampaign,
            lineItems: Array<ProveLineItem>,
            start: Date,
            end: Date
        }
    ) => {
        if (payload.campaign.id === 'demo') {
            return proveOverviewReportDemoData;
        }

        return RetrieveProveOverviewReportDataApi.execute(
            payload.configuration,
            payload.campaign,
            payload.lineItems,
            payload.start,
            payload.end
        );
    }
)

export const retrieveProveBreakdownReportDataAsync = createAppAsyncThunk(
    'prove/dashboard/breakdownReportData',
    async (
        payload: {
            configuration: Configuration,
            campaign: ProveCampaign,
            lineItems: Array<ProveLineItem>,
            start: Date,
            end: Date,
            breakdown: Array<string>
        },
    ) => {
        if (payload.campaign.id === 'demo') {
            if (payload.breakdown.length > 1) {
                return proveBreakdownHierarchyReportDemoData;
            }
            // we use startsWith because the breakdown might override the size - e.g. publisherId:100
            if (payload.breakdown[0].startsWith('deviceType')) {
                return proveBreakdownDeviceReportDemoData;
            }
            if (payload.breakdown[0].startsWith('publisherId')) {
                return proveBreakdownPublisherReportDemoData;
            }
            if (payload.breakdown[0].startsWith('adFormat')) {
                return proveBreakdownAdFormatReportDemoData;
            }
            if (payload.breakdown[0].startsWith('lineItem')) {
                return proveBreakdownLineItemReportDemoData;
            }
        }

        return RetrieveProveBreakdownReportDataApi.execute(
            payload.configuration,
            payload.campaign,
            payload.lineItems,
            payload.start,
            payload.end,
            payload.breakdown
        );
    }
)
