import { CountryCodeIso2 } from '@api-clients/shared';

export const AvailableCountries = [
    CountryCodeIso2.Ae,
    CountryCodeIso2.Au,
    CountryCodeIso2.Be,
    CountryCodeIso2.Ca,
    CountryCodeIso2.De,
    CountryCodeIso2.Fr,
    CountryCodeIso2.Ie,
    CountryCodeIso2.Mx,
    CountryCodeIso2.Nz,
    CountryCodeIso2.Pl,
    CountryCodeIso2.Sa,
    CountryCodeIso2.Gb,
    CountryCodeIso2.Us,
] as const;
