/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Subscriptions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const FeatureCode = {
    OrganisationSeatLimit: 'Organisation_Seat_Limit',
    MediaPlansCreateMediaPlan: 'MediaPlans_Create_MediaPlan',
    MediaPlansCreateScenario: 'MediaPlans_Create_Scenario',
    MediaPlansViewScenario: 'MediaPlans_View_Scenario',
    MediaPlansAccessCountryAe: 'MediaPlans_Access_Country_Ae',
    MediaPlansAccessCountryAu: 'MediaPlans_Access_Country_Au',
    MediaPlansAccessCountryBe: 'MediaPlans_Access_Country_Be',
    MediaPlansAccessCountryCa: 'MediaPlans_Access_Country_Ca',
    MediaPlansAccessCountryDe: 'MediaPlans_Access_Country_De',
    MediaPlansAccessCountryFr: 'MediaPlans_Access_Country_Fr',
    MediaPlansAccessCountryIe: 'MediaPlans_Access_Country_Ie',
    MediaPlansAccessCountryMx: 'MediaPlans_Access_Country_Mx',
    MediaPlansAccessCountryNz: 'MediaPlans_Access_Country_Nz',
    MediaPlansAccessCountrySa: 'MediaPlans_Access_Country_Sa',
    MediaPlansAccessCountryGb: 'MediaPlans_Access_Country_Gb',
    MediaPlansAccessCountryUs: 'MediaPlans_Access_Country_Us',
    MediaPlansAccessCountryGlobal: 'MediaPlans_Access_Country_Global',
    MediaPlansCustomiseGenders: 'MediaPlans_Customise_Genders',
    MediaPlansCustomiseAgeGroups: 'MediaPlans_Customise_AgeGroups',
    MediaPlansCustomiseFormatCpms: 'MediaPlans_Customise_FormatCpms',
    StrategyReportCreateReport: 'Strategy_Report_Create_Report',
    StrategyReportAccessCountryAe: 'Strategy_Report_Access_Country_Ae',
    StrategyReportAccessCountryAu: 'Strategy_Report_Access_Country_Au',
    StrategyReportAccessCountryBe: 'Strategy_Report_Access_Country_Be',
    StrategyReportAccessCountryCa: 'Strategy_Report_Access_Country_Ca',
    StrategyReportAccessCountryDe: 'Strategy_Report_Access_Country_De',
    StrategyReportAccessCountryFr: 'Strategy_Report_Access_Country_Fr',
    StrategyReportAccessCountryIe: 'Strategy_Report_Access_Country_Ie',
    StrategyReportAccessCountryMx: 'Strategy_Report_Access_Country_Mx',
    StrategyReportAccessCountryNz: 'Strategy_Report_Access_Country_Nz',
    StrategyReportAccessCountryPl: 'Strategy_Report_Access_Country_Pl',
    StrategyReportAccessCountrySa: 'Strategy_Report_Access_Country_Sa',
    StrategyReportAccessCountryGb: 'Strategy_Report_Access_Country_Gb',
    StrategyReportAccessCountryUs: 'Strategy_Report_Access_Country_Us',
    StrategyReportAccessCountryGlobal: 'Strategy_Report_Access_Country_Global',
    AttentionDataApiAccess: 'AttentionData_Api_Access',
    AttentionDataBenchmarkAccess: 'AttentionData_Benchmark_Access',
    AttentionDataAccessCountryAe: 'AttentionData_Access_Country_Ae',
    AttentionDataAccessCountryAu: 'AttentionData_Access_Country_Au',
    AttentionDataAccessCountryBe: 'AttentionData_Access_Country_Be',
    AttentionDataAccessCountryCa: 'AttentionData_Access_Country_Ca',
    AttentionDataAccessCountryDe: 'AttentionData_Access_Country_De',
    AttentionDataAccessCountryFr: 'AttentionData_Access_Country_Fr',
    AttentionDataAccessCountryIe: 'AttentionData_Access_Country_Ie',
    AttentionDataAccessCountryMx: 'AttentionData_Access_Country_Mx',
    AttentionDataAccessCountryNz: 'AttentionData_Access_Country_Nz',
    AttentionDataAccessCountryPl: 'AttentionData_Access_Country_Pl',
    AttentionDataAccessCountrySa: 'AttentionData_Access_Country_Sa',
    AttentionDataAccessCountryGb: 'AttentionData_Access_Country_Gb',
    AttentionDataAccessCountryUs: 'AttentionData_Access_Country_Us',
    AttentionDataAccessCountryGlobal: 'AttentionData_Access_Country_Global',
    AttentionDataBenchmarkAccessCountryAe: 'AttentionData_Benchmark_Access_Country_Ae',
    AttentionDataBenchmarkAccessCountryAu: 'AttentionData_Benchmark_Access_Country_Au',
    AttentionDataBenchmarkAccessCountryBe: 'AttentionData_Benchmark_Access_Country_Be',
    AttentionDataBenchmarkAccessCountryCa: 'AttentionData_Benchmark_Access_Country_Ca',
    AttentionDataBenchmarkAccessCountryDe: 'AttentionData_Benchmark_Access_Country_De',
    AttentionDataBenchmarkAccessCountryFr: 'AttentionData_Benchmark_Access_Country_Fr',
    AttentionDataBenchmarkAccessCountryIe: 'AttentionData_Benchmark_Access_Country_Ie',
    AttentionDataBenchmarkAccessCountryMx: 'AttentionData_Benchmark_Access_Country_Mx',
    AttentionDataBenchmarkAccessCountryNz: 'AttentionData_Benchmark_Access_Country_Nz',
    AttentionDataBenchmarkAccessCountrySa: 'AttentionData_Benchmark_Access_Country_Sa',
    AttentionDataBenchmarkAccessCountryGb: 'AttentionData_Benchmark_Access_Country_Gb',
    AttentionDataBenchmarkAccessCountryUs: 'AttentionData_Benchmark_Access_Country_Us',
    AttentionDataBenchmarkAccessCountryGlobal: 'AttentionData_Benchmark_Access_Country_Global',
    AttentionDataApiAccessCountryAe: 'AttentionData_Api_Access_Country_Ae',
    AttentionDataApiAccessCountryAu: 'AttentionData_Api_Access_Country_Au',
    AttentionDataApiAccessCountryBe: 'AttentionData_Api_Access_Country_Be',
    AttentionDataApiAccessCountryCa: 'AttentionData_Api_Access_Country_Ca',
    AttentionDataApiAccessCountryDe: 'AttentionData_Api_Access_Country_De',
    AttentionDataApiAccessCountryFr: 'AttentionData_Api_Access_Country_Fr',
    AttentionDataApiAccessCountryIe: 'AttentionData_Api_Access_Country_Ie',
    AttentionDataApiAccessCountryMx: 'AttentionData_Api_Access_Country_Mx',
    AttentionDataApiAccessCountryNz: 'AttentionData_Api_Access_Country_Nz',
    AttentionDataApiAccessCountrySa: 'AttentionData_Api_Access_Country_Sa',
    AttentionDataApiAccessCountryGb: 'AttentionData_Api_Access_Country_Gb',
    AttentionDataApiAccessCountryUs: 'AttentionData_Api_Access_Country_Us',
    AttentionDataApiAccessCountryGlobal: 'AttentionData_Api_Access_Country_Global',
    AarcApiAccess: 'AARC_Api_Access',
    AarcAccessCountryAe: 'AARC_Access_Country_Ae',
    AarcAccessCountryAu: 'AARC_Access_Country_Au',
    AarcAccessCountryBe: 'AARC_Access_Country_Be',
    AarcAccessCountryCa: 'AARC_Access_Country_Ca',
    AarcAccessCountryDe: 'AARC_Access_Country_De',
    AarcAccessCountryFr: 'AARC_Access_Country_Fr',
    AarcAccessCountryIe: 'AARC_Access_Country_Ie',
    AarcAccessCountryMx: 'AARC_Access_Country_Mx',
    AarcAccessCountryNz: 'AARC_Access_Country_Nz',
    AarcAccessCountrySa: 'AARC_Access_Country_Sa',
    AarcAccessCountryGb: 'AARC_Access_Country_Gb',
    AarcAccessCountryUs: 'AARC_Access_Country_Us',
    AarcAccessCountryGlobal: 'AARC_Access_Country_Global',
    ProveApiAccess: 'Prove_Api_Access',
    ProveSaasAccess: 'Prove_Saas_Access',
    ProveApiSessionAccess: 'Prove_Api_Session_Access',
} as const;
export type FeatureCode = typeof FeatureCode[keyof typeof FeatureCode];

export function FeatureCodeFromJSON(json: any): FeatureCode {
    return FeatureCodeFromJSONTyped(json, false);
}

export function FeatureCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeatureCode {
    return json as FeatureCode;
}

export function FeatureCodeToJSON(value?: FeatureCode | null): any {
    return value as any;
}
